<template>
    <div>   
        <v-simple-table  :height="tableHeight" fixed-header>
            <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">
                        Vloga
                    </th>
                    <th class="text-left">
                        Imetnik
                    </th>
                    <th class="text-left">
                        Plačnik
                    </th>
                    <th class="text-left">
                        Referenca
                    </th>
                    <th class="text-left">
                        Znesek
                    </th>
                    <th class="text-left">
                        Datum plačila
                    </th>
                    <th class="text-right">
                        Urejanje
                    </th>
                </tr>
            </thead>
            <tbody v-if="applications.length > 0">
                <tr
                v-for="item in applications"
                :key="item.uuid"
                >
                <td>{{ item.id }}</td>
                <td>{{ item. name }}</td>
                <td>{{ item.payer_name }}</td>
                <td>{{ item.reference }}</td>
                <td>{{ item.amt }}</td>
                <td>{{ item.bookg_dt_formatted }}</td>
                <td class="text-right">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <a :href="editApplicationUrl(item)" style="text-decoration:none;">
                                <v-icon
                                id="dt_btn_edit"
                                color="success"
                                small
                                class="mr-2"
                                v-on="on"
                                >
                                mdi mdi-file-document-edit-outline
                                </v-icon>
                            </a>
                        </template>
                        <span>Uredi vlogo</span>
                    </v-tooltip>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>

import { EventBus } from '@/main.js'

export default {
    props: ['applications'],
    name: 'paid-application-table',
    data: () => ({
        data: ['applications'],
        
    }),

    watch: {

    },

    computed: {
        tableHeight() {

            if(this.applications.length > 5 && this.applications.length <= 10) {
                return "350px";
            }

            if(this.applications.length > 10) {
                return "500px"
            }

            return "250px"
        }
    },

    methods: {
        editApplicationUrl(item) {
            let itemLocation = ""
            let location = ""

            if(item.digital == 1) {
                location = this.$router.resolve({name: 'admin_applications_edit', params: {id:item.uuid}})
            } else {
                location = this.$router.resolve({name: 'admin_applications_edit2', params: {id:item.uuid}})
            }
            
            itemLocation = window.location.origin + location.href;

            return itemLocation
        }
    },

    created() {
        //window.console.log("PaidApplicationTable.vue")
    },

    mounted() {
        //window.console.log("Applications data")
        //window.console.log(this.applications)
    },

    beforeDestroy() {
        this.data = []
    },

    destroyed() {

    }
}

</script>

<style scoped>

</style>