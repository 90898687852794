<template>
    <div>
        <v-layout row wrap :align-center="!wideLayout" :justify-center="!wideLayout" :fill-height="!wideLayout">
            <v-flex xs12 sm12 :md12="wideLayout" :lg12="wideLayout" :md10="!wideLayout" :lg10="!wideLayout" :xl8="!wideLayout">
                

                <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="totalItems"
                :loading="loading"
                class="elevation-1"
                :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageOptions: [5,10,15,25,50, 100]
                }"
                >
                <template v-slot:top>
                    <v-toolbar class="elevation-1" color="white">
                        <v-toolbar-title class="font-weight-regular body-1">{{ settings.title}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-flex xs4 md4 lg4 v-show="breakPoint">
                            <!--<v-text-field class="pt-4"  label="Išči..." @keyup.esc="search = ''" v-model="search" append-icon="mdi-magnify" single-line></v-text-field>-->
                            <v-text-field class="pt-4"  label="Išči..." @keyup.esc="search = ''" @keyup="doSearch()" v-model="search" append-icon="mdi-magnify" single-line clearable @click:clear="doSearch()"></v-text-field>
                        </v-flex>
                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <v-tooltip v-if="btnRefresh" top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="refreshDataTable" class="toolbar_btn_fab" elevation="0" color="secondary" v-on="on" fab x-small dark>
                                    <v-icon>mdi-sync</v-icon>
                                </v-btn>
                            </template>
                            <span>Osveži podatke</span>
                        </v-tooltip>
                        <v-tooltip v-if="btnToggleColumns" top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggleColumns()" class="toolbar_btn_fab" elevation="0" color="secondary" v-on="on" fab x-small dark>
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <span>Filtriraj stolpce</span>
                        </v-tooltip>
                    </v-toolbar>
                    <br>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_account"
                                color="secondary"
                                small
                                v-on="on"
                                class="mr-2"
                                @click="viewPaymentList(item)"
                            >
                                mdi-format-list-bulleted
                            </v-icon>
                        </template>
                        <span>Seznam plačanih vlog</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_account"
                                color="error"
                                small
                                v-on="on"
                                class="mr-2"
                                @click="sendInvoices(item)"
                            >
                                mdi-email-outline
                            </v-icon>
                        </template>
                        <span>Pošlji račune</span>
                    </v-tooltip>
                    <!--
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_account"
                                color="error"
                                small
                                v-on="on"
                                class="mr-2"
                                @click="viewPaymentList(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Odstrani uvožena plačila</span>
                    </v-tooltip>-->
                </template>

                <template v-slot:item.imported="{ item }">
                    <v-chip label v-if="item.imported !== null && item.imported !=='null' && item.imported !== ''" :color="getImportedColor(item.imported)" dark small>{{ importedText(item.imported) }}</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:item.paid_applications_count="{ item }">
                    <v-chip label v-if="item.paid_applications_count !== null && item.paid_applications_count !=='null' && item.paid_applications_count !== ''" :color="getTotalPaidApplicationsColor()" dark small>{{ item.paid_applications_count }}</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:item.unpaid_applications_count="{ item }">
                    <v-chip label v-if="item.unpaid_applications_count !== null && item.unpaid_applications_count !=='null' && item.unpaid_applications_count !== ''" :color="getTotalUnpaidApplicationsColor()" dark small>{{ item.unpaid_applications_count }}</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:progress>
                    <v-progress-linear absolute  indeterminate :height="progressHeight()"></v-progress-linear>
                </template>
                <template slot="no-data">
                    <div class="no_data" v-show="!searchQuery">{{ (this.loading) ? "Obdelava podatkov je v teku ..." : "Ni podatkov za prikaz." }}</div>
                    <div class="no_data" v-show="searchQuery">{{ (this.loading) ? 'Obdelava podatkov za iskani niz "'  + this.search + '" je v teku ...' :  `Ni podatkov za iskani niz »${this.search}«.`}}</div>
                </template>
                <template slot="loading">
                    <div class="no_data">Obdelava podatkov je v teku ...</div>
                </template>
                </v-data-table>
            </v-flex>

            <v-dialog v-model="dialogSendInvoices" persistent max-width="490">
                <v-card>
                    <v-card-title class="dialog_card_title">
                        <span class="subtitle-1 font-weight-medium"><v-icon left>mdi-email</v-icon>Pošlji račune</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container grid-list-xl>
                                Ste prepričani, da željite nadaljevati s pošiljanjem računov uporabnikom izbranih vlog?
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="error" @click.native="sendInvoicesDialogCancel()">Prekliči</v-btn>
                        <v-btn text :loading="loading" color="success" @click.native="dialogSendInvoicesConfirm()">Nadaljuj</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>

        <!-- dialog -->
        <v-dialog v-model="applicationsListDialog" max-width="1000px">
        <v-card>
          <v-card-title class="dialog_card_title">
            <span class="subtitle-1 font-weight-medium"><v-icon left>mdi-format-list-bulleted</v-icon>Seznam plačanih vlog {{ computedPaymentId }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
              <!--<div id="linear_progress" style="margin-top:20px;">
                  <v-progress-linear indeterminate></v-progress-linear>
              </div>-->
              <br>
                <!--<payed-applications-data-table></payed-applications-data-table>-->
                <paid-application-table :applications="getItemApplications"></paid-application-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="error" @click.native="applicationsListDialogCancel()">Zapri</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { post, get, put, destroy, API_ENDPOINT, DEMO_API_ENDPOINT } from '@/helpers/api'
import { EventBus } from '@/main.js'
import PaidApplicationTable from './PaidApplicationTable.vue'
//const PayedApplicationsDataTable = () => import('@/components/PayedApplicationsDataTable.vue')
const PaidAplicationTable = () => import('@/components/PaidApplicationTable.vue')

export default {
    props: ['settings', 'queryParameters', 'boxedLayout'],
    name: 'imported-payments-data-table',

    components: {
        //PayedApplicationsDataTable
        PaidApplicationTable
    },

    data: () => ({
       
        search: '',
        totalItems: 0,
        items: [],
        loading: true,
        options: {},
        rowsPerPageItems: [5,10,15,25,50,100, -1], 
        interval: null,
        dialog: false,
        dialog_text: '',
        itemToDelete: null,
        applicationsListDialog: false,
        dialogSendInvoices: false,
        sendInvoicesSelectedData: null,
        paymentUuid: null,
        paymentId: "",
        applications: [],
        searchTimer: null
    }),

    watch: {
        options: {
            handler () {
            this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })

                //window.console.log(this.options)
                //window.console.log(this.params)
            },
            deep: true,
        },

        /*search(newValue) {
            this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })
        }*/

        searchFromStore(newValue) {
            this.search = newValue
            this.doSearch()
        }
    },

    computed: {
        params(nv) {
            return {
                ...this.options,
                query: this.search
            }
        },

        searchQuery() {
           return (this.search != null && this.search.trim() != '') ? true : false;
       },

       btnRefresh() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.refresh) {
               return true
           }

           return false
       },

       btnExport() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.export) {
               return true
           }

           return false
       },

       btnAddNew() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.addNew) {
               return true
           }

           return false
       },

       btnToggleColumns() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.toggleColumns && 
                this.settings.eventBus && this.settings.eventBus.toggleColumnsId != null && this.settings.eventBus.toggleColumnsId.trim() != ''
           ) {
               return true
           }

           return false
       },

       breakPoint() {
           if(this.$vuetify.breakpoint.mdAndUp) {
                return true;
            }

             return false;
       },

       apiParams() {
           let params = {
                ...this.options,
                query: this.search,
                columns: this.settings.headers.map(header => {
                    return header.value
                })
            }

            if(this.queryParameters && this.queryParameters != null) {
                let queryParams = this.queryParameters
                params = {
                    ...params,
                    ...queryParams
                }
            }

            //window.console.log("queryParams")
            //window.console.log(params)

           return params;
       },

       headers() {
           return this.settings.headers.filter(header => {
               if(header.visibility) {
                   return header
               }
           });
       },

       searchFromStore: {
           get() {
               return this.$store.getters.search
           },

           set(value) {
               this.$store.commit('SET_SEARCH', this.search)
           }
       },

       wideLayout() {
           if(this.boxedLayout !== undefined) {
               if(this.boxedLayout === true) {
                   return false
               }

               return true
           }

           return true
       },

       computePaymentUuid() {
           return this.paymentUuid
       },

       computedPaymentId() {
           return (this.paymentId != "" && this.paymentId != null) ? "(ID. uvozenih plačil: " + this.paymentId + ")" : ""
       },

       getItemApplications() {
           return this.applications
       }
    },

    methods: {
        getDataFromApi () {
            this.loading = true
            
            if(this.settings.hasOwnProperty('eventBus') && this.settings.eventBus.hasOwnProperty('totalItems')) {
                if(this.settings.eventBus.totalItems) {
                    EventBus.$emit(this.settings.eventBus.totalItems.event, {progress: true, value: 0})
                }
            }

            if(this.settings.hasOwnProperty('eventBus') && this.settings.eventBus.hasOwnProperty('sumItems')) {
                if(this.settings.eventBus.sumItems) {
                    EventBus.$emit(this.settings.eventBus.sumItems.event, {progress: true, queryParameters: this.apiParams})
                }
            }

            return new Promise((resolve, reject) => {
                //post(API_ENDPOINT + this.settings.endpoint, this.apiParams)
                post(API_ENDPOINT + this.settings.endpoint, this.apiParams)            
                .then(response => {
                    //window.console.log("API data -- ImportedPaymentsDataTable")
                    //window.console.log(response.data)
                    const {
                       sortBy,
                       sortDesc, 
                       page,
                       itemsPerPage 
                    } = this.options;

                    //this.pagination.rowsPerPage = response.data.meta.per_page
                    //console.log(response)
                    const items = response.data.data
                    const totalItems = response.data.meta.total

                    if(this.settings.hasOwnProperty('eventBus') && this.settings.eventBus.hasOwnProperty('totalItems')) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {value: totalItems})
                        }
                    }

                    resolve({items, totalItems})
                })
                .catch(error => {
                    if(this.settings.hasOwnProperty('eventBus') && this.settings.eventBus.hasOwnProperty('totalItems')) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {value: 0})
                        }
                    }
                    reject(error)
                })
                .finally(() => {
                    this.loading = false
                    if(this.settings.hasOwnProperty('eventBus') && this.settings.eventBus.hasOwnProperty('totalItems')) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {progress: false})
                        }
                    }
                })
            })
      },

       addNewItem() {
           if(this.settings.toolbar.buttons.addNewRoute != '' && this.settings.toolbar.buttons.addNewRoute != null) {
               this.$router.push({name: this.settings.toolbar.buttons.addNewRoute})
           }
       },

       refreshDataTable() {
           this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })
       },

       toggleColumns() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.toggleColumns && 
                this.settings.eventBus && this.settings.eventBus.toggleColumnsId != null && this.settings.eventBus.toggleColumnsId.trim() != '') {
                    EventBus.$emit(this.settings.eventBus.toggleColumnsId, {
                        toggleColumnsMenu: true
                    });
            }
       },

       toggleDataTableColumns(data) {
           if(Object.prototype.hasOwnProperty.call(data, 'dataTableHeaders') ) {
               //window.console.log(data)
                for(var i=0; i<this.settings.headers.length; i++) {
                    var headerFound = false;
                    for(var k=0; k<data.dataTableHeaders.length; k++) {
                        if(this.settings.headers[i].value == data.dataTableHeaders[k]) {
                            this.settings.headers[i].visibility = true
                            headerFound = true
                        }
                    }

                    if(headerFound == false) {
                        //console.log(this.dataTableSettings.headers[i])
                        this.settings.headers[i].visibility = false
                    }
                }

           }
       },

       progressHeight() {
           if(this.settings.hasOwnProperty('progressHeight') && this.settings.progressHeight != null && this.settings.progressHeight != '') {
               return this.settings.progressHeight
           }

           return 3
       },

       getTotalPaidApplicationsColor() {

           return 'light-blue darken-3'
       },

       getTotalUnpaidApplicationsColor() {
           return "#e91e63";
       },

       getImportedColor(item) {
           if(item == 1) {
               return 'success'
           }

            return "warning"
       },

       importedText(item) {
           if(item == 1 || item == true) {
               return 'Uvoz plačil je končan'
           }

           return "Uvoz plačil je v teku..."
       },

       viewPaymentList(item) {
           //window.console.log(item)
           this.paymentUuid = item.uuid
           this.paymentId = item.id
           this.applications = item.applications
           var vm = this
           setTimeout(() => {
               vm.applicationsListDialog = true
            //naredi popupd (dialog) --- znotraj pa datatable oz. tabelo vseh pripadajočih vlog ... poleg podatkov o vlogi
            //naj bo v tabeli še gum za povezavo na posamezno vlogo
            
           }, 200)
       },

       sendInvoices(item) {
           //window.console.log("Sending invoices...");
           //window.console.log(item)
           this.sendInvoicesSelectedData = item
           this.dialogSendInvoices = true
       },

       applicationsListDialogCancel() {
           this.applicationsListDialog = false
           this.applications = []
       },

       sendInvoicesDialogCancel() {
           this.sendInvoicesSelectedData = null
           this.dialogSendInvoices = false
       },

       dialogSendInvoicesConfirm() {
           if(this.sendInvoicesSelectedData != null) {
                var vm = this
                vm.loading = true

                vm.$store.dispatch('SEND_INVOICES', vm.sendInvoicesSelectedData.uuid )
                .then(response => {
                    window.console.log(response.data)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Računi so bili uspešno dodani v vrsto za pošiljanje.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                })
                .catch(error => {
                    window.console.error("### PaymentsDataTable@dialogSendInvoicesConfirm ###");
                    window.console.error(error)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Pri dodajanju računov v vrsto za pošiljanje je prišlo do napake.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                })
                .finally(() => {
                    vm.loading = false
                    vm.sendInvoicesDialogCancel()
                })
           }
       },

       doSearch() {
           let vm = this
           if(this.searchTimer) {
               clearTimeout(this.searchTimer)
               this.searchTimer = null
           }

           this.searchTimer = setTimeout(() => {
               vm.getDataFromApi()
                .then(data => {
                   vm.items = data.items
                   vm.totalItems = data.totalItems
               })
               .catch(error => {
                   vm.items = [];
                   vm.totalItems = 0;
                   window.console.log(error)
               })
           }, 600)
       }
    },

    created() {
        /*window.console.log("DataTableSettings");
        window.console.log(this.settings)

        window.console.log("Query Parameters")
        window.console.log(this.queryParameters)*/
    },

    mounted() {
        if(this.settings && this.settings.sortColumn) {
            this.options.sortBy = this.settings.sortColumn
        }

        if(this.settings && this.settings.sortDirection && this.settings.sortDirection == 'desc') {
            this.options.sortDesc = this.settings.sortDirection
        }

        if(this.settings && this.settings.hasOwnProperty('eventBus')) {
            if(this.settings.eventBus.refreshDataTable != null && this.settings.eventBus.refreshDataTable.trim() != '' ) {
                EventBus.$on(this.settings.eventBus.refreshDataTable, (data) => {
                    this.refreshDataTable()
                })
            }

            if(this.settings.eventBus.search != null && this.settings.eventBus.search.trim() != '') {
                EventBus.$on(this.settings.eventBus.search, (data) => {
                    this.search = data
                })
            }

            if(this.settings.id != null && this.settings.id !== undefined) {
                EventBus.$on(this.settings.id, (data) => {
                    this.toggleDataTableColumns(data)
                })
            }
        }

        if(this.settings && this.settings.updateInterval) {
            this.interval = setInterval(() => {
                if(this.loading != true) {
                    this.getDataFromApi()
                    .then(data => {
                        this.items = data.items
                        this.totalItems = data.totalItems
                    })
                    .catch(error => {
                        this.items = [];
                        this.totalItems = 0;
                        window.console.log(error)
                    })
                }
            }, process.env.VUE_APP_DATA_TABLES_REFRESH_INTERVAL || 180000)
       }

       if(this.settings && this.settings.rowsPerPage) {
           this.options.itemsPerPage = this.settings.rowsPerPage
       }

       //window.console.log("Boxed Layout: " + this.boxedLayout)
    },

    beforeDestroy() {
        this.applications = []
    },

    destroyed() {
        clearInterval(this.interval)
        if(this.settings && this.settings.eventBus  && this.settings.eventBus.refreshDataTable) {
            if(this.settings.eventBus.refreshDataTable != null && this.settings.eventBus.refreshDataTable.trim() != '' ) {
                EventBus.$off(this.settings.eventBus.refreshDataTable)
            }
        } 

        EventBus.$off(this.settings.id)
        this.sendInvoicesSelectedData = null
    }
}

</script>

<style scoped>
    .no_data {
        text-align:center !important;
    }

    #style-11::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
    }
    
    #style-11::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0,0,0,0.1);
        border: 1px solid #ccc;
    }

    #style-11::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: linear-gradient(left, #fff, #e4e4e4);
        border: 1px solid #aaa;
    }

    #style-11::-webkit-scrollbar-thumb:hover {
        background: #fff;
    }

    #style-11::-webkit-scrollbar-thumb:active {
        background: linear-gradient(left, #22ADD4, #1E98BA);
    }

    .toolbar_btn_fab {
        margin-right:10px;
    }

    #dt_btn_account {
        font-size:18px !important;
    }

    #dt_btn_edit {
        font-size:18px !important;
    }

    #dt_btn_delete {
        font-size:18px !important;
    }

    .no_data {
        color: #000 !important;
    }

    .v-card__text {
        color: rgba(0,0,0,0.90) !important;
    }


</style>